<template>
  <div>
    <ContactInformationFormStep
      class="contact-information__form-step mb-8"
      :progress-value="progressValue"
    />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  name: 'ContactInformationFormPage',

  components: {
    ContactInformationFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Contactgegevens',
      path: '/offertes-aanvragen/je-gegevens',
      progressValue: 90,
      checkoutStep: 1,
    }
  },
}
</script>
