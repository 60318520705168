<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="address-street"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'address-street'
export default {
  name: 'AddressStreetField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: 'address-line1',
    },

    placeholder: {
      type: String,
      default: 'Straatnaam',
    },
  },

  /**
   * Component state
   *
   * @returns {object}
   */
  data() {
    return {
      value: '',
    }
  },

  watch: {
    /**
     * V-model
     *
     * @param value
     */
    value(value) {
      this.reset()
    },
  },

  methods: {
    /**
     * @returns {*[]}
     */
    customErrorMap() {
      return [
        {
          id: ValidationError.reasonIsRequired,
          message: this.$i18n.t('field.address-street.validation.required'),
        },
        {
          id: ValidationError.reasonInvalidRegex,
          message: this.$i18n.t('field.address-street.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidLength,
          message: this.$i18n.t('field.address-street.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidValue,
          message: this.$i18n.t('field.address-street.validation.invalid'),
        },
      ]
    },
  },
}
</script>
