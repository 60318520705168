<template>
  <div class="phone-form-part">
    <div class="phone-form-part__column">
      <PhoneField v-on="$listeners" />
    </div>
    <div class="phone-form-part__column">
      <span
        id="openDialog"
        v-track-click
        class="phone-form-part__open-dialog-btn"
        @click.stop="showModal = true"
      >
        Waarom mijn <br />
        telefoonnummer?
      </span>

      <WhyPhoneDialog
        v-model="showModal"
        :title="$t('title')"
        :text="$t('text')"
        :close-button-text="$t('closeButtonText')"
        :image-src="dialogImageSrc"
      />
    </div>
  </div>
</template>

<script>
import WhyPhoneDialog from 'chimera/all/components/dialog/WhyPhoneDialog'
import PhoneField from 'chimera/all/components/form/fields/phone/PhoneField'

export default {
  name: 'PhoneFormPart',

  components: {
    PhoneField,
    WhyPhoneDialog,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      showModal: false,
    }
  },

  computed: {
    /**
     * Get localized image
     *
     * @returns {*}
     */
    dialogImageSrc() {
      let imgSrc = require('chimera/all/assets/images/phone-dialog.svg')
      if (this.$i18n.locale !== 'nl-NL') {
        imgSrc = require('chimera/all/assets/images/phone-dialog-be.svg')
      }
      return imgSrc
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Waarom willen jullie mijn telefoonnummer hebben?",
    "text": "Onze makelaars maken speciaal voor jou geheel vrijblijvend een offerte op maat, zo weet je tot in detail wat je kunt verwachten zonder dat je kosten maakt. Hierdoor kan je de verschillende makelaars makkelijk met elkaar vergelijken. Om dit zo goed en eerlijk mogelijk te kunnen doen nemen onze makelaars alle wensen telefonisch met je door.",
    "closeButtonText": "Verder met je aanvraag"
  },
  "nl-BE": {
    "title": "Waarom willen jullie mijn telefoonnummer hebben?",
    "text": "Onze immokantoren maken speciaal voor u geheel vrijblijvend een offerte op maat, zo weet u tot in detail wat u kunt verwachten zonder dat u kosten maakt. Hierdoor kan u de verschillende immokantoren makkelijk met elkaar vergelijken. Om dit zo goed en eerlijk mogelijk te kunnen doen nemen onze immokantoren alle wensen telefonisch met u door.",
    "closeButtonText": "Verder met uw aanvraag"
  }
}
</i18n>
