<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="postal"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :error-messages="errors"
    outlined
    required
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationRules from 'chimera/all/functions/ValidationRules'
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'postal'
export default {
  name: 'PostalField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    placeholder: {
      type: String,
      default: 'Postcode',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      value: '',
    }
  },

  watch: {
    /**
     * V-model
     *
     * @param {string} value
     */
    value(value) {
      this.reset()
    },
  },

  methods: {
    /**
     * @param {Array} errors
     * @param {Array} rawErrors
     */
    afterOnInvalid(errors, rawErrors) {
      // When our back-end doesn't recognise the postal, we want to emit this on the bus
      // so we can log/track these and improve our coverage.
      if (rawErrors.pop() === ValidationError.reasonUnknownPostal) {
        this.$eventBus.emitPostalNotFoundEvent(this.country, this.value)
      }
    },

    /**
     * @returns {Array}
     */
    validationRules() {
      return ValidationRules.getRules([
        ...ValidationRules.isPostalFormat(this.country),
      ])
    },

    /**
     * @returns {*[]}
     */
    customErrorMap() {
      return [
        {
          id: ValidationError.reasonIsRequired,
          message: this.$i18n.t('field.postal.validation.required'),
        },
        {
          id: ValidationError.reasonInvalidRegex,
          message: this.$i18n.t('field.postal.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidLength,
          message: this.$i18n.t('field.postal.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidValue,
          message: this.$i18n.t('field.postal.validation.invalid'),
        },
      ]
    },
  },
}
</script>
