<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="phone"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    inputmode="tel"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationRules from 'chimera/all/functions/ValidationRules'
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'phone'
export default {
  name: 'PhoneField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    autocomplete: {
      type: String,
      default: 'tel',
    },

    placeholder: {
      type: String,
      default: 'Telefoon',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      value: '',
    }
  },

  watch: {
    /**
     * @param {any} value
     */
    value(value) {
      this.reset()
    },
  },

  methods: {
    /**
     * Form field validation rules
     *
     * @returns {Array}
     */
    validationRules() {
      return ValidationRules.getRules([...ValidationRules.isMinimalLength(9)])
    },

    /**
     * @returns {*[]}
     */
    customErrorMap() {
      return [
        {
          id: ValidationError.reasonIsRequired,
          message: this.$i18n.t('field.phone.validation.required'),
        },
        {
          id: ValidationError.reasonInvalidRegex,
          message: this.$i18n.t('field.phone.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidLength,
          message: this.$i18n.t('field.phone.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidValue,
          message: this.$i18n.t('field.phone.validation.invalid'),
        },
        {
          id: ValidationError.reasonBlacklisted,
          message: this.$i18n.t('field.phone.validation.invalid'),
        },
      ]
    },
  },
}
</script>
